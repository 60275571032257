import React from "react"
import "../styles/mindbody.scss"
import accountStyles from "../components/account.module.scss"
import Layout from '../components/layout-default'
import Breadcrumbs from "../components/breadcrumbs";

const crumbs = [
  {
    label: "Home",
    link: "",
  },
  {
    label: "Bookings",
    link:"bookings"
  },
];

const Bookings = () => (
  <Layout
    title="RF Dance Studio Rentals: Spacious Studios for Creatives"
    description="Ready to learn dance? Book our spacious RF Dance Studios for your dance lessons. Get the ideal environment to hone your dance skills with our affordable rates." 
    pathname="bookings/"
    noHero="no-hero">
    <div className="container">
      <Breadcrumbs crumbs={crumbs} />
      
      <iframe 
        className={accountStyles.iframe}
        title="RF Dance Studio Rental Booking"
        id="mindbody_branded_web_cart_modal" 
        height="800"
        frameBorder="0" 
        marginWidth="0" 
        marginHeight="0" 
        scrolling="yes"
        src="https://app.acuityscheduling.com/schedule.php?owner=17766906&appointmentType=category:Studio%20Rental%20" />
    </div>
  </Layout>
)

export default Bookings